* {
  margin: 0;
  padding: 0;
  line-height: 1;
}
body {
  background: #1A1A1A;
  color: #FFDCA8;
  font-family: "Nexa-bold", Arial, sans-serif;
  margin: 0;
}
body.hidden {
  overflow: hidden;
}
body.show {
  overflow-y: scroll;
}
.content {
  clear:both;
  min-height: 100%;
}
header,
footer {
  padding: 0 30px;
  z-index: 1000;
}
header {
  padding-top: 30px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.logo a {
  font-family: "Nexa-bold";
  font-size: 36px;
  color: #fff;
  display: flex;
  align-items: center;
  float: left;
  cursor: pointer;
}
.name {
  margin-top: -2px;
  text-transform: uppercase;
  float: left;
}
.logo p {
  font-size: 18px;
  font-family: "Moret";
  color: #5AACFF;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  margin-top: -3px;
}
.logo img {
  width: 36px;
  height: 63px;
  float: left;
  margin-right: 10px;
}
header .menu {
  float: right;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
}
header .menu svg {
  margin-left: 15px;
}
footer {
  clear: both;
  font-size: 14px;
  font-family: "Moret";
  z-index: 2;
  padding-bottom: 20px;
  margin-top: auto;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
footer.relative {
  position: relative;
}
footer.fixed {
  position: fixed;
}
footer p > span {
  text-transform: uppercase;
}
footer > div {
  width: 50%;
  float: left;
}
footer .social {
  display: inline;
}
footer .social li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}
footer .social li > a {
  color: #FFDCA8;
  font-size: 14px;
}
footer p > .svg-inline--fa {
  height: 12px;
  margin-left: 8px;
  margin-right: 10px;
}
footer .copyright {
  font-family: "Menlo";
  font-size: 10px;
  color: #808080;
  text-transform: uppercase;
  margin-top: 8px;
}
footer .copy-right {
  text-align: right;
  float: right;
}
footer .copy-right p {
  display: inline-block;
  font-family: "Nexa-bold";
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
  margin-right: 15px;
}
footer .copy-right h4 {
  float: right;
  font-size: 24px;
  margin-top: 2px;
}
footer .copy-right h4 a {
  color: #FFDCA8;
  text-decoration: none;
}

h1 {
  font-size: 130px;
}
h1.h1-smaller {
  font-size: 100px;
}
.txt-white {
  color: #fff;
}
.txt-right {
  text-align: right;
}
.txt-default {
  color: #FFDCA8;
}

.mainContent {
  padding-top: 80px;
}
.about {
  padding-top: 90px;
}
.content-center {
  font-family: "Moret", sans-serif;
  text-align: center;
  width: 70%;
  margin: auto;
  text-shadow: 9px 14px 9px rgba(0,0,0,0.3);
  max-width: 650px;
  position: relative;
  padding-top: 70px;
}
.content-center h1 {
  font-size: 130px;
  text-transform: uppercase;
  line-height: 120px;
}
.content-center h1 a {
  color: #FFDCA8;
  text-decoration: none;
}
.background-images {
  overflow: hidden;
}
.clouds {
  position: absolute;
  height: auto;
  top: 50px;
  left: 18%;
  z-index: -1;
}
.moving-clouds {
  width: 980px;
  height: 550px;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}
.moving-clouds.clouds-1 {
  left: 136px;
  top: -30px;
}
.moving-clouds.clouds-2 {
  left: 8px;
  bottom: -50px;
}
.moving-clouds.clouds-3 {
  right: 40px;
  top: 69px;
}
.view-link-outer {
  display: flex;
  justify-content: center;
  z-index: 2;
  padding-left: 350px;
  margin-top: 15px;
}
.view-link {
  z-index: 2;
  text-align: right;
  color: #FFDCA8;
  display: flex;
  position: relative;
  padding-left: 50px;
  text-decoration: none;
  align-items: center;
  text-transform: uppercase;
}
.view-link > span {
  float: left;
  font-size: 14px;
  line-height: 20px;
}
.view-link .svg-inline--fa {
  margin-left: 13px;
}
.enter-sentinels-link {
  z-index: 100;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -40%);
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  color: #FDF0DD;
}
.web2 .enter-sentinels-link {
  bottom: auto;
}
.enter-sentinels-link img {
  max-width: 15px;
}
.enter-sentinels-link p {
  margin-top: 7px;
}
.enter-sentinels-link p,
.enter-sentinels-link img {
  cursor: pointer;
}
.about-s2,
.about-s3,
.about-s5,
.about-s7,
.about-s9,
.about-s10 {
  padding: 0 30px;
}
.about-s4 {
  padding: 0 0 0 30px;
}
.hero.hero-about {
  display: flex;
  align-items: center;
  top: -93px;
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding: 0 0 0 30px;
}
.hero.hero-about .hero-content-right img {
  width: 90%;
  margin-left: 90px;
}
.about h1 span {
  display: block;
}
.hero.hero-about .hero-content {
  width: 50%;
}

h1.about-header {
  font-size: 70px;
  font-weight: normal;
}
.about .animated-text span {
  display: inline;
}
.about .hero-about h2,
.about-section.about-s8 h4 {
  font-size: 24px;
  font-family: "Moret";
  text-transform: uppercase;
  color: #5AACFF;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 120px;
  text-align: left;
}
.about .hero-about .cloud {
  background: url('./images/cloud-1.svg') no-repeat;
  width: 127px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
}
.about .hero-about .cloud span {
  margin-top: 15px;
  font-size: 13px;
}
.about .hero-about .cloud.cloud1 {
  top: 25%;
  left: 4%;
}
.about .hero-about .cloud.cloud2 {
  top: 30%;
  left: 35%;
}
.about .hero-about .cloud.cloud3 {
  top: 55%;
  left: 44%;
}
.about .hero-about .cloud.cloud4 {
  top: 80%;
  left: 33%;
  background: url('./images/cloud-3.svg') no-repeat;
  width: 167px;
  height: 91px;
}
.about .hero-about .cloud.cloud4 span {
  margin-top: 20px;
}
.about .hero-about .cloud.cloud5 {
  left: 6%;
  top: 78%;
}
.about h2,
.about h2 span.revert-default {
  font-size: 100px;
  font-family: 'Moret';
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9;
}
.about h2 span.revert-default {
  line-height: 40px;
}
.about h2 span.txt-default {
  color: #FFDCA8;
}
.about h2 span {
  font-family: 'Nexa-Bold';
  color: #fff;
  display: block;
  font-size: 90px;
  line-height: 90px;
}
.about h2 span.txt-default {
  color: #FFDCA8;
}
.about-section {
  padding-top: 125px;
  padding-bottom: 125px;
}
.about-section.about-s1 {
  margin-top: 100px;
}
.about-section.about-s1 p {
  font-size: 24px;
  line-height: 40px;
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
  font-family: 'NexaRegular';
  margin-top: 30px;
}
.about-section.about-s2 > div {
  text-align: center;
}
.about-section.about-s2 > div img {
  width: 49%;
}
.about-section.about-s2 h2 {
  margin-top: -300px;
  margin-bottom: 230px;
}
.about-section.about-s2 p {
  font-family: 'Moret';
  font-size: 50px;
  margin-top: 50px;
}
.about-section.about-s3 h3 {
  text-align: center;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Moret';
}
.about-section.about-s3 h3 span {
  font-family: 'Nexa-Bold';
}
.about-section.about-s3 p {
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin-bottom: 25px;
}
.about-section.about-s3 .logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.about-section.about-s3 .logos ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.about-section.about-s3 .logos li {
  list-style: none;
}
.about-section.about-s3 .logos img {
  height: 56px;
  width: auto;
  margin: 0 30px;
  margin-top: 75px;
}
.about-section.about-s4 {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
.about-section.about-s4 > div {
  width: 50%;

  float: left;
}
.about-section.about-s4 h2 {
  text-align: right;
}
.about-section.about-s4 h2 span.word1,
.about-section.about-s4 h2 span.word4 {
  font-family: 'Moret';
  color: #FFDCA8;
  text-align: center;
}
.about-section.about-s4 h2 span.word2 {
  font-size: 55px;
}
.about-section.about-s4 h2 span.word4 {
  text-align: center;
  margin-left: 205px;
  margin-top: -10px;
}
.about-section.about-s4 .carousel {
  margin-left: 100px;
}
.about-section.about-s4 .poster-carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: grab;
}
.about-section.about-s4 .poster-carousel li {
  min-width: 28rem;
  min-height: 1rem;
  list-style: none;
}
.about-section.about-s4 .poster-carousel li img {
  height: 550px;
  width: auto;
  pointer-events: none;
}
.about-section.about-s5 h3 {
  font-size: 70px;
}
.about-section.about-s5 h4 {
  font-size: 20px;
}
.about-section.about-s5 td {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  vertical-align: top;
}
.about-section.about-s5 tr:nth-child(1) td {
  padding-top: 35px;
}
.about-section.about-s5 tr:nth-child(2) td,
.about-section.about-s5 tr:nth-child(3) td {
  padding: 80px 0 40px 0;
}
.about-section.about-s5 tr:nth-child(3) td {
  padding-bottom: 55px;
}
.about-section.about-s5 td:last-child {
  border-right: 0 none;
}
.about-section.about-s5 .about-pixar li {
  width: 100%;
}
.about-section.about-s5 li {
  margin-bottom: 40px;
}
.about-section.about-s5 tr td:nth-child(2) {
  padding-left: 55px;
}
.about-section.about-s5 li {
  list-style: none;
  float: left;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.about-section.about-s5 li img {
  max-width: 100%;
  height: 75px;
  width: auto;
}
.about-section.about-s6 {
  text-align: center;
}
.about-section.about-s6 h3,
.about-section.about-s8 h3,
.about-section.about-s10 h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
  padding-top: 190px;
  text-align: center;
}
.about-section.about-s6 h2 {
  font-size: 140px;
  line-height: 200px;
  margin-top: -35px;
}
.about-section.about-s6 img {
  margin-top: -500px;
  margin-bottom: 30px;
  max-width: 80%;
}
.about-section.about-s6 a {
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 20px 30px 15px 30px;
  margin-bottom: 30px;
}
.about-section.about-s6 p {
  margin-top: 30px;
}
.about-section.about-s6 a svg {
  font-size: 12px;
  margin-left: 30px;
}
.about-section.about-s6 h2 span {
  font-size: 130px;
}
.about-section.about-s7 p {
  font-size: 65px;
  font-family: "Moret";
  margin-bottom: 50px;
}
.about-section.about-s7 .s7-buttons img {
  float: left;
  max-width: 13%;
}
.about-section.about-s7 .s7-buttons div {
  float: left;
  margin-top: 20px;
  margin-left: 20px;
}
.about-section.about-s7 .s7-buttons a {
  color: #FDF0DD;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
}
.about-section.about-s7 .s7-buttons span {
  font-family: 'Moret';
  font-size: 20px;
}
.about-section.about-s7 .s7-buttons svg {
  margin-top: 35px;
  margin-left: 15px;
}
.about-section.about-s7 .s7-buttons .link-right {
  float: right;
  text-align: right;
}
.about-section.about-s7 .s7-buttons .link-right svg {
  margin-top: 24px;
}
.about-section.about-frames {
  overflow-x: hidden;
}
.about-section.about-frames > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-section.about-frames img {
  width: 180px;
}
.about-section.about-frames a:nth-child(1) img {
  margin-top: 220px;
}
.about-section.about-frames a:nth-child(2) img {
  margin-left: 80px;
}
.about-section.about-frames a:nth-child(3) img {
  margin-left: -110px;
  margin-top: 140px;
}
.about-section.about-frames a:nth-child(4) img {
  margin-left: 60px;
  width: 440px;
  margin-top: 90px;
  margin-right: 50px;
}
.about-section.about-frames a:nth-child(6) img {
  margin-left: -110px;
  margin-top: 140px;
}
.about-section.about-frames a:nth-child(7) img {
  margin-top: 220px;
  margin-left: 80px;
}
.about-section.about-frames p {
  padding: 0 70px;
  text-align: center;
  margin-top: 60px;
  font-size: 35px;
  line-height: 45px;
}
.about-section.about-frames p span {
  font-family: "Moret";
}
.about-section.about-s8 small {
  line-height: 0;
  font-size: 50px;
}
.about-section.about-s8 h4 {
  margin-top: 30px;
  text-align: center;
  text-transform: none;
  font-size: 30px;
}
.about-section.about-s8 h3 {
  padding-top: 0;
}
.about-section.about-s9 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 250px;
}
.about-section.about-s9 > div:last-child {
  margin-bottom: 0;
}
.about-section.about-s9 .about-s9-1 {
  font-size: 40px;
  font-family: 'Moret';
  text-align: right;
  color: #fff;
}
.about-section.about-s9 .about-s9-2 {
  font-size: 300px;
  font-family: "Moret";
  margin-top: -50px;
  margin-left: 20px;
}
.about-section.about-s9 .about-s9-3 {
  font-size: 80px;
  align-self: center;
  color: #fff;
  margin-left: 15px;
  margin-top: 80px;
}
.about-section.about-s9 .about-s9-4 {
  font-family: 'Moret';
  font-size: 40px;
  text-transform: uppercase;
  color: #fff;
  align-self: center;
  margin-left: -30px;
  margin-top: 170px;
}
.about-section.about-s9 .about-s9-5 {
  font-size: 360px;
  font-family: 'Moret';
  color: #fff;
  line-height: 240px;
  margin-right: 10px;
}
.about-section.about-s9 .about-s9-5.smaller {
  font-size: 280px;
}
.about-section.about-s9 .about-s9-float {
  float: left;
}
.about-section.about-s9 .about-s9-float span {
  display: block;
}
.about-section.about-s9 .about-s9-6,
.about-section.about-s9 .about-s9-8 {
  font-size: 70px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Moret";
  margin-bottom: 5px;
}
.about-section.about-s9 .about-s9-9 {
  font-size: 70px;
  line-height: 30px;
}
.about-section.about-s9 .about-s9-9.push-down {
  margin-top: 28px;
}
.about-section.about-s9 .about-s9-8 {
  text-transform: none;
  margin-bottom: 10px;
}
.about-section.about-s9 .about-s9-7,
.about-section.about-s10 .about-s9-7 {
  font-size: 22px;
  line-height: 30px;
}
.about-section.about-s9 .about-s9-10 {
  margin-left: 60px;
  font-size: 24px;
  line-height: 1.45833333333;
  margin-top: 20px;
}
.about-section.about-s9 .about-s9-6.push-down2 {
  margin-bottom: 20px;
}
.about-section .about-s9-9.fix-line-height {
  line-height: 1;
  text-align: center;
}
.about-section .about-s9-9.fix-line-height .push-down3 {
  margin-left: 30px;
}
.about-section .about-s9-9.fix-line-height .txt-default {
  font-family: "Moret";
}
.about-section .about-s9-9.fix-line-height img {
  max-width: 8%;
  position: relative;
  top: 20px;
  left: 10px;
}
.about-section .about-s9-9.fix-line-height .off-screen {
  text-indent: -10000px;
  position: absolute;
}
.about-section.about-s9 .about-s9-11 {
  font-size: 60px;
}
.about-section.about-s9 .about-s9-11 span {
  font-family: "Moret";
}
.about-section.about-s9 .about-s9-11 img {
  width: 200px;
}
.about-s10-container {
  display: flex;
  align-items: flex-end;
}
.about-section.about-s10 h3 {
  margin-bottom: 30px;
  padding-top: 0;
}
.about-section.about-s10 h2 {
  text-transform: none;
  color: #fff;
  font-weight: normal;
}
.about-section.about-s10 h2 span {
  color: #FFDCA8;
  line-height: 60px;
  text-transform: uppercase;
}
.about-section.about-s10 svg {
  font-size: 60px;
  margin-right: 20px;
}
.about-section.about-s10 .about-s10-left {
  display: flex;
  margin-top: 100px;
  float: left;
  width: 64%;
}
.about-section.about-s10 .about-s10-right img {
  width: 51.5%;
  margin: 0 auto;
  display: flex;
}

.mainContent.contact {
  padding: 0 30px;
  padding-top: 80px;
}
.mainContent.contact .content-center {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.mainContent.contact .content-center .content-left,
.mainContent.contact .content-center .content-right {
  display: flex;
  float: left;
  height: 78vh;
}
.mainContent.contact .content-center .content-left {
  width: 57%;
  flex-direction: column;
  justify-content: center;
}
.mainContent.contact .content-center .content-right {
  width: 43%;
  align-items: center;
}
.mainContent.contact .content-center .content-left h1 {
  text-align: left;
  font-size: 100px;
  line-height: 90px;
}
.mainContent.contact .content-center .content-left h1 span {
  color: #fff;
}
.mainContent.contact .content-center .content-left h1 span:nth-child(1) {
  font-family: 'Nexa-Bold';
  line-height: 0px;
}
.mainContent.contact .content-center .content-left .contact-info > a {
  float: left;
  color: #FDF0DD;
  margin-top: 30px;
}
.mainContent.contact .content-center .content-left .social {
  float: right;
  margin-top: 30px;
}
.mainContent.contact .content-center .content-left .social li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}
.mainContent.contact .content-center .content-left .social li a {
  color: #FDF0DD;
}
.mainContent.contact .content-center .content-right form {
  padding-left: 70px;
}
.mainContent.contact .content-center .content-right p {
  font-family: "Nexa-Bold";
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-family: "NexaRegular";
  margin-bottom: 40px;
  line-height: 30px;
}
.mainContent.contact .content-center .content-right form input,
.mainContent.contact .content-center .content-right form textarea {
  background: none;
  border: 0 none;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin: 0 0 0 10px;
  width: 280px;
  font-size: 20px;
  font-family: "NexaRegular";
  color: #fff;
}
.mainContent.contact .content-center .content-right form textarea {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 70px;
}
.mainContent.contact .content-center .content-right form input,
.mainContent.contact .content-center .content-right form textarea {
  outline: none;
}
.mainContent.contact .content-center .content-right form button {
  float: left;
  background: none;
  border: 2px solid #fff;
  padding: 20px 30px 15px 30px;
  color: #fff;
  font-family: "Nexa-Bold";
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}
.mainContent.contact .content-center .content-right form button .svg-inline--fa {
  margin-left: 20px;
  height: 15px;
}
.gallery-full-image img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: auto;
}
.portfolio-menu {
  float: left;
  padding-left: 30px;
}
.portfolio-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff;
}
.portfolio-menu .css-10d9dml-MuiTabs-indicator {
  background-color: #fff;
}
.portfolio-menu li,
.portfolio-menu a,
.portfolio-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  list-style: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
  font-family: "NexaRegular";
  cursor: pointer;
  font-size: 16px;
  color: #FFDCA8;
  text-decoration: none;
  text-align: left;
  padding: 0;
  padding-right: 15px;
  min-width: 0;
  align-items: flex-start;
}
.portfolio-menu img {
  width: 75px;
  height: auto;
  margin-top: 150px;
}
.portfolio.web2 {
  padding-right: 30px;
  text-align: right;
}
.portfolio.web2 .next-item-container {
  position: absolute;
  right: 30px;
  bottom: 125px;
}
.portfolio.web2 .slider__btn-left {
  position: absolute;
  right: 30px;
  bottom: 100px;
  cursor: pointer;
}
.portfolio.web2 .next-item-container button {
  border: 0 none;
  background: none;
}
.portfolio.web2 .next-image-box {
  width: 280px;
  height: 140px;
  cursor: pointer;
}
.portfolio.web2 .next-image-box img {
  width: 100%;
}
.css-1g4ac1o-MuiTabs-root {
  position: absolute;
  top: 40%
}
.web2 .enter-sentinels-link span {
  padding: 0;
  margin: 0 auto;
  color: #FDF0DD;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}
.next-image-box {
  clear: both;
  background-size: cover;
  background-position: center center;
}
.next-item-container p {
  font-size: 14px;
  font-family: "Moret";
  text-transform: uppercase;
  color: #FDF0DD;
  margin-bottom: 10px;
}
.next-item-container p span {
  display: block;
  font-family: "Nexa-Bold";
  font-size: 20px;
  line-height: 30px;
}
.portfolio.web2 .enter-sentinels-link {
  position: absolute;
  bottom: 0;
  max-width: 280px;
}
.portfolio.web2 .enter-sentinels-link p {
  margin-top: 30px;
}

.carousel {
  cursor: grab;
  overflow: hidden;
  margin-left: 200px;
  margin-top: 10px;
}
.portfolio.nft .items {
  display: flex;
  padding-top: 10px;
  margin-bottom: 20px;
}
.portfolio.nft .items li {
  list-style: none;
  min-width: 23rem;
  min-height: 30rem;
  padding: 0 20px 20px 20px;
  position: relative;
}
.portfolio.nft .items li .image-container {
  height: 370px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 15px;
}
.portfolio.nft .items li img {
  width: auto;
  height: 370px;
  pointer-events: none;
}
.portfolio.nft .items .description.hidden {
  display: none;
}
.portfolio.nft .items .nft-count,
.portfolio.nft .items .nft-name {
  font-family: "Moret";
  font-size: 32px;
}
.portfolio.nft .items .nft-name a {
  color: #FFDCA8;
  text-decoration: none;
}
.portfolio.nft .items .nft-name {
  float: left;
}
.portfolio.nft .items .nft-count {
  float: right;
}
.portfolio.nft .items .nft-price {
  clear: both;
  padding-top: 10px;
  color: #fff;
  margin-bottom: 25px;
}
.portfolio.nft .items .item--links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.portfolio.nft .items .view-description {
  font-size: 13px;
  text-decoration: underline;
  float: left;
  cursor: pointer;
}
.portfolio.nft .items .view-link-outer {
  margin: 0;
  padding: 0;
}
.portfolio.nft .items .view-link-outer span {
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  line-height: 1;
}
.description--modal-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(0,0,0,0.9);
  z-index: 3;
  overflow-y: scroll;
  padding: 30px;
  padding-top: 80px;
}
.portfolio.nft .close-modal {
  right: 30px;
  top: 30px;
}
.portfolio.web2 .slider {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.portfolio.web2 .slider.fadeIn {
  animation: fadeIn 1.3s;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.sentinels--loading,
.overlay {
  position: fixed;
  overflow: hidden;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sentinels--loading img {
  max-width: 100%;
  margin-top: -100px;
}
.overlay {
  background: #1A1A1A;
  z-index: 0;
  opacity: 0.9;
}
.screen--container {
  position: relative;
}
.sentinels--screen1,
.sentinels--screen2,
.sentinels--screen3,
.sentinels--screen4 {
  text-align: center;
}
.sentinels--screen1 h1,
.sentinels--screen2 h2 {
  font-family: "Moret";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 180px;
}
.view-collection-link {
  position: absolute;
  bottom: 30px;
  z-index: 3;
}
.view-collection-link > span {
  float: left;
}
.view-collection-link a {
  color: #FFDCA8;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.view-collection-link .link-text {
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
}
.sentinels--screen2 h2 {
  font-size: 160px;
}
.sentinels--screen1 p {
  max-width: 700px;
  margin: 0 auto;
  text-transform: uppercase;
  line-height: 1.3;
}
.sentinels--screen2 h2 span {
  font-family: 'Nexa-bold';
}
.screen--container {
  justify-content: center;
  align-items: center;
  display: flex;
}
.sentinels--screen3 {
  position: relative;
  flex-direction: column;
}
.sentinels--screen3 p {
  max-width: 900px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 30px;
}
.sentinels--screen3 img {
  max-width: 20%;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.sentinels--screen4 p {
  font-size: 36px;
  line-height: 50px;
  max-width: 800px;
  text-align: center;
}
.sentinels--screen4 p span {
  font-family: 'Moret';
}
.sentinels--menu {
  display: flex;
  position: relative;
}
.sentinels--menu .left {
  width: 50%;
}
.sentinels--menu .right {
  width: 50%;
}
.sentinels--menu ul {
  margin-top: 30px;
  margin-left: 100px;
  width: 72%;
}
.sentinels--menu ul li {
  width: 110px;
  list-style: none;
  display: inline-flex;
  margin: 0 10px 10px 0;
}
.sentinels--menu img {
  width: 100%;
  height: 100%;
  min-width: 100%;
}
.sentinels--menu li div {
  position: relative;
  width: 110px;
  height: 110px;
  overflow: hidden;
}
.sentinels--menu li div span {
  position: absolute;
  left: 10px;
  bottom: 5px;
  font-size: 12px;
  color: #FDF0DD;
}
.sentinels--menu .right {
  text-align: right;
  padding-right: 120px;
}
.sentinels--menu .right h3 {
  font-size: 24px;
  text-transform: uppercase;
}
.sentinels--menu .right h2 {
  font-size: 160px;
  text-transform: uppercase;
  font-family: "Moret";
  margin-bottom: 20px;
}
.sentinels--menu .right p {
  font-size: 20px;
  line-height: 32px;
  max-width: 670px;
  margin: 0 0 0 auto;
}
.sentinels--menu .left .helper-msg {
  position: absolute;
  left: -20px;
  transform: rotate(-90deg);
  top: 47%;
  font-size: 12px;
}
.sentinel--container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sentinel--container .sentinel--image {
  width: 100%;
  position: absolute;
}
.sentinel--container .sentinel--content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.sentinel--container .sentinel--content h1 {
  font-size: 110px;
  text-transform: uppercase;
  font-family: "Moret";
}
.sentinel--container .sentinel--content .description {
  font-size: 14px;
  line-height: 20px;
}
.sentinel--container .sentinel--content a {
  color: #FDF0DD;
  font-size: 14px;
  text-transform: uppercase;
}
.sentinel--container .view-collection-link {
  right: 30px;
  bottom: 25%
}
.sentinel--container .sentinel--controls {
  position: absolute;
  bottom: 18%;
  left: 30px;
}
.sentinel--container .sentinel--controls .controls {
  display: flex;
}
.sentinel--container .sentinel--controls .next a,
.sentinel--container .sentinel--controls .prev a,
.sentinel--container .sentinel--controls .view-all {
  font-size: 14px;
  font-family: "Moret";
  text-decoration: none;
  color: #FFDCA8;
  cursor: pointer;
}
.sentinel--container .sentinel--controls .view-all {
  text-decoration: underline;
}
.sentinel--container .sentinel--controls .prev {
  margin-right: 35px;
}
.sentinel--container .sentinel--controls .next {
  margin-left: 35px;
}
.sentinel--container .sentinel--controls h2 {
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.sentinel--container .sentinels--menu {
  z-index: 999;
  position: absolute;
}
.sentinel--container .sentinels--menu ul {
  max-width: 480px;
  background: rgba(26,26,26,0.9);
  padding: 10px;
  padding-top: 40px;
  border-radius: 8px;
}
.sentinel--container .sentinels--menu ul li {
  margin: 5px;
}

/* Portfolio Item specific */
.traversal .sentinel--content {
  display: flex;
  max-width: 500px;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  flex-direction: column;
  margin-bottom: 100px;
}
.traversal .sentinel--content .description {
  margin: 10px 0 30px 0;
}
.traversal .sentinel--content a,
.solaceAndDiscovery .sentinel--container .sentinel--content a {
  position: initial;
}
.solaceAndDiscovery .sentinel--content {
  left: 30px;
  top: 20%;
  max-width: 700px;
  position: absolute;
}
.solaceAndDiscovery .sentinel--container .sentinel--content h1 {
  font-size: 70px;
}
.solaceAndDiscovery .sentinel--container .sentinel--content .description {
  max-width: 450px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.arise .sentinel--container .sentinel--content h1 {
  position: absolute;
  top: 24%;
  left: 159px;
}
.arise .sentinel--container .sentinel--content .description {
  position: absolute;
  right: 80px;
  top: 27%;
  width: 340px;
  text-align: center;
}
.arise .sentinel--container .sentinel--content a {
  position: absolute;
  top: 40%;
  left: 159px;
}
.juniper .sentinel--content h1 {
  position: absolute;
  bottom: 40px;
  left: 38%;
}
.juniper .sentinel--content .description {
  max-width: 340px;
  left: 30px;
  position: absolute;
  top: 23%;
}
.juniper .sentinel--content a {
  position: absolute;
  bottom: 145px;
  top: auto;
  left: 38%;
}
.passage .sentinel--content h1 {
  position: absolute;
  bottom: 20px;
  left: 32%;
}
.passage .sentinel--content .description {
  position: absolute;
  bottom: 129px;
  max-width: 420px;
  left: 32%;
}
.passage .sentinel--content a {
  position: absolute;
  bottom: 290px;
  left: 32%;
}
.mirror .sentinel--content h1 {
  position: absolute;
  top: 30%;
  left: 32px;
}
.mirror .sentinel--content .description {
  position: absolute;
  top: 10%;
  left: 33%;
}
.mirror .sentinel--content a {
  position: absolute;
  top: 46%;
  left: 30px;
}
.fortyForth .sentinel--content {
  padding-left: 30px;
  margin-top: 330px;
}
.fortyForth .sentinel--content .description,
.petrichor .sentinel--content .description,
.morvan .sentinel--content .description,
.maelstrom .sentinel--content .description {
  max-width: 480px;
  margin: 10px 0 30px 0;
}
.petrichor .sentinel--content,
.morvan .sentinel--content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}
.petrichor .sentinel--content .description {
  text-align: center;
}
.persistence-of-dreams .sentinel--content h1 {
  position: absolute;
  left: 30px;
  top: 22%;
  max-width: 500px;
  color: #1A1A1A;
}
.persistence-of-dreams .sentinel--content .description {
  position: absolute;
  right: 72px;
  top: 24%;
  max-width: 260px;
  color: #1A1A1A;
}
.persistence-of-dreams .sentinel--content a {
  position: absolute;
  top: 55%;
  left: 30px;
  color: #1A1A1A;
}
.persistence-of-dreams .sentinel--container .sentinel--controls .next a,
.persistence-of-dreams .sentinel--container .sentinel--controls .prev a,
.persistence-of-dreams .sentinel--container .sentinel--controls .view-all,
.persistence-of-dreams .sentinel--container .sentinel--controls,
.persistence-of-dreams .view-collection-link a {
  color: #1A1A1A;
}
.morvan .sentinel--content .description {
  text-align: center;
  max-width: 350px;
}
.sleeping-giants .sentinel--content {
  text-align: center;
}
.sleeping-giants .sentinel--content h1 {
  position: absolute;
  left: 27%;
  top: 10%;
}
.sleeping-giants .sentinel--content .description {
  position: absolute;
  top: 26%;
  left: 27%;
}
.sleeping-giants .sentinel--content a {
  position: absolute;
  left: 27%;
  top: 32%;
}
.maelstrom .sentinel--content {
  margin: 330px 0 0 30px;
}

.grid-view {
  padding-top: 180px;
  padding-left: 280px;
  display: flex;
  background: #1A1A1A;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.grid-view ul {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.grid-view ul li {
  list-style: none;
  max-width: 33.333%;
  min-width: 300px;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  margin: 0 15px 30px 15px;
  cursor: pointer;
}
.grid-view ul li img {
  width: auto;
  height: 100%;
}
.grid-view ul li .image-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-grid-view {
  position: absolute;
  margin-top: -50px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}
.close-grid-view span {
  color: #fff;
}

.close-modal {
  position: absolute;
  right: 140px;
  top: 48px;
  cursor: pointer;
}
.juniper .sentinel--image {
  margin-top: 450px;
}

.error-image {
  max-width: 100%;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.error-overlay {
  background: rgba(0,0,0,0.24);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}
.error-content h1 {
  font-family: 'AlphaClouds';
  color: #fff;
  font-size: 350px;
  text-shadow: 9px 14px 9px rgba(0,0,0,0.3);
  margin-bottom: 30px;
}
.error-content h2 {
  font-size: 40px;
  font-family: "Moret";
  color: #fff;
  text-shadow: 0 3px 6px rgba(0,0,0,0.3);
  font-weight: normal;
  margin-bottom: 20px;
}
.error-content a {
  border: 2px solid #fff;
  padding: 15px 40px 11px 40px;
  text-decoration: none;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.menu-icon:checked + label,
.menu-icon:not(:checked) + label {
  position: fixed;
  top: 63px;
  right: 75px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}
.menu-icon:checked + label:before,
.menu-icon:not(:checked) + label:before {
  position: absolute;
  content: "";
  display: block;
  width: 30px;
  height: 20px;
  z-index: 20;
  top: 0;
  left: 0;
  border-top: 2px solid #FFDCA8;
  border-bottom: 2px solid #FFDCA8;
  transition: border-width 100ms 1500ms ease,
    top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
    height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after,
.menu-icon:not(:checked) + label:after {
  position: absolute;
  content: "";
  display: block;
  width: 22px;
  height: 2px;
  z-index: 20;
  top: 10px;
  right: 4px;
  background-color: #FFDCA8;
  margin-top: -1px;
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease,
    margin-top 100ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:before {
  top: 10px;
  transform: rotate(45deg);
  height: 2px;
  background-color: #FFDCA8;
  border-width: 0;
  transition: border-width 100ms 340ms ease,
    top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after {
  width: 30px;
  margin-top: 0;
  right: 0;
  transform: rotate(-45deg);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.nav {
  position: fixed;
  top: 33px;
  right: 50px;
  display: block;
  width: 80px;
  height: 80px;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.3);
  background-color: #2B2A2A;
  animation: border-transform 7s linear infinite;
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
    right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 250ms 1100ms ease,
    width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
    height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.menu-icon:checked ~ .nav {
  animation-play-state: paused;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 200%;
  height: 200%;
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),
    right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 700ms ease,
    width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
    height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
}

.nav ul {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: center;
  transform: translateY(-50%);
  list-style: none;
}

.nav ul li {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 250ms linear;
}

.nav ul li:nth-child(2) {
  transition-delay: 200ms;
}
.nav ul li:nth-child(3) {
  transition-delay: 150ms;
}
.nav ul li:nth-child(4) {
  transition-delay: 100ms;
}
.nav ul li:nth-child(5) {
  transition-delay: 50ms;
}

.nav ul li a {
  font-family: "Montserrat", sans-serif;
  font-size: 9vh;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 800;
  display: inline-block;
  position: relative;
  color: #FFDCA8;
  transition: all 250ms linear;
}

.nav ul li a:hover {
  text-decoration: none;
  color: #fff;
}

.nav ul li a:after {
  display: block;
  position: absolute;
  top: 50%;
  content: "";
  height: 2vh;
  margin-top: -1vh;
  width: 0;
  left: 0;
  background: #353746;
  opacity: 0.8;
  transition: width 250ms linear;
}

.nav ul li a:hover:after {
  width: 100%;
}
.nav .enter-sentinels {
  position: absolute;
  top: -30%;
  z-index: 99999999999;
  left: 28%;
}
.nav .enter-sentinels a {
  text-decoration: none;
  color: #FFDCA8;
  letter-spacing: 2px;
}

.menu-icon:checked ~ .nav ul li {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease, transform 250ms ease;
}

.menu-icon:checked ~ .nav ul li:nth-child(2) {
  transition-delay: 1400ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(3) {
  transition-delay: 1480ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(4) {
  transition-delay: 1560ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(5) {
  transition-delay: 1640ms;
}


@media only screen and (max-width: 1357px) {
  h1.about-header {
    font-size: 60px;
  }
  .about-section.about-s7 p {
    font-size: 55px;
  }
  .about .hero-about h2, .about-section.about-s8 h4,
  .about-section.about-s1 p,
  .about-section.about-s9 .about-s9-10 {
    font-size: 22px;
  }
  .about-section.about-s1 p {
    line-height: 36.52px;
  }
  .about .hero-about .cloud {
    transform: scale(0.9) !important;
  }
  .about h2, .about h2 span.revert-default {
    font-size: 90px;
  }
  .about h2 span {
    font-size: 80px;
    line-height: 1;
  }
  .about-section {
    padding-top: 115px;
    padding-bottom: 115px;
  }
  .about-section.about-s2 h2 {
    margin-top: -260px;
    margin-bottom: 200px;
  }
  .about-section.about-s2 p {
    font-size: 45px;
    line-height: 1;
  }
  .about-section.about-s3 h3 {
    font-size: 19px;
  }
  .about-section.about-s4 h2 span.word2 {
    font-size: 50px;
  }
  .about-section.about-s5 h3 {
    font-size: 60px;
  }
  .about-section.about-s5 h4 {
    font-size: 18px;
  }
  .about-section.about-s6 h2 {
    font-size: 130px;
    line-height: 1;
    margin-top: 0;
  }
  .about-section.about-s6 h2 span {
    font-size: 120px;
  }
  .about-section.about-frames img {
    width: 160px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 400px;
  }
  .about-section.about-frames p {
    font-size: 32px;
    line-height: 42px;
  }
  .about-section.about-s6 h3, .about-section.about-s8 h3, .about-section.about-s10 h3 {
    font-size: 22px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 35px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 70px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 270px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 220px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 20px;
    line-height: 27.27px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 63.636363px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 327.272724px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 254.545452001px;
  }
  .about-section .about-s9-9.fix-line-height img {
    top: 10px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 54.54px;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 90px;
  }
  .mainContent.contact .content-center .content-right p,
  .mainContent.contact .content-center .content-right form input, .mainContent.contact .content-center .content-right form textarea {
    font-size: 18px;
  }
  .mainContent.contact .content-center .content-right form button {
    font-size: 16px;
  }
  .sentinels--screen1 h1 {
    font-size: 160px;
  }
  .sentinels--screen2 h2,
  .sentinels--menu .right h2 {
    font-size: 140px;
  }
  .sentinels--screen1 p {
    font-size: 15px;
  }
  .sentinels--screen3 p,
  .sentinels--menu .right p {
    font-size: 18px;
  }
  .sentinels--screen3 p {
    line-height: 1.5;
  }
  .sentinels--menu .right p {
    line-height: 1.8;
  }
  .sentinels--screen4 p {
    font-size: 32px;
    line-height: 1.4;
  }
  .sentinels--menu .right h3 {
    font-size: 22px;
  }
  .sentinels--menu .right {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 1235px) {
  h1.about-header {
    font-size: 50px;
  }
  .about-section.about-s7 p {
    font-size: 45px;
  }
  .about-section.about-s6 h2 {
    font-size: 120px;
  }
  .about-section.about-s6 h2 span {
    font-size: 110px;
  }
  .about .hero-about h2, .about-section.about-s8 h4,
  .about-section.about-s1 p,
  .about-section.about-s9 .about-s9-10 {
    font-size: 20px;
  }
  .about-section.about-s2 p {
    font-size: 40px;
    line-height: 1;
  }
  .about-section.about-s5 h3 {
    font-size: 40px;
  }
  .about-section.about-s1 p {
    line-height: 33.2px;
  }
  .about .hero-about .cloud {
    transform: scale(0.85) !important;
  }
  .about h2, .about h2 span.revert-default {
    font-size: 80px;
  }
  .about h2 span {
    font-size: 70px;
  }
  .about-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .about-section.about-s4 h2 span.word2 {
    font-size: 40px;
  }
  .about-section.about-s5 h4 {
    font-size: 16px;
  }
  .about-section.about-s5 tr td:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .about-section.about-frames img {
    width: 150px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 390px;
  }
  .about-section.about-frames p {
    font-size: 28px;
    line-height: 38px;
  }
  .about-section.about-s6 h3, .about-section.about-s8 h3, .about-section.about-s10 h3 {
    font-size: 20px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 30px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 60px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 240px;
  }
  .about-section.about-s9 .about-s9-4 {
    margin-top: 160px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 190px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 18px;
    line-height: 24.548px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 57.2727272728px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 294.545454546px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 229.090909092px;
  }
  .about-section.about-s9 .about-s9-6.push-down2 {
    margin-bottom: 16px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 49.090909091px;
  }
  .about-section.about-s10 h3 {
    margin-bottom: 20px;
  }
  .about-section.about-s10 h2 span {
    line-height: 0.6;
  }
  .about-section.about-s10 svg {
    font-size: 36px;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 80px;
    line-height: 1;
  }
  .mainContent.contact .content-center .content-right p,
  .mainContent.contact .content-center .content-right form input, .mainContent.contact .content-center .content-right form textarea {
    font-size: 17px;
  }
  .mainContent.contact .content-center .content-right form button {
    font-size: 15px;
  }
  .sentinels--screen1 h1 {
    font-size: 140px;
  }
  .sentinels--screen2 h2,
  .sentinels--menu .right h2 {
    font-size: 120px;
  }
  .sentinels--screen1 p {
    font-size: 14px;
  }
  .sentinels--screen3 p,
  .sentinels--menu .right p {
    font-size: 17px;
  }
  .sentinels--screen4 p {
    font-size: 30px;
  }
  .sentinels--menu .right h3 {
    font-size: 20px;
  }
  .view-collection-link .link-text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1110px) {
  h1.about-header {
    font-size: 40px;
  }
  .about-section.about-s7 p {
    font-size: 35px;
    margin-bottom: 40px;
  }
  .about-section.about-s6 h2 {
    font-size: 110px;
  }
  .about-section.about-s6 h2 span {
    font-size: 100px;
  }
  .about .hero-about h2, .about-section.about-s8 h4,
  .about-section.about-s1 p,
  .about-section.about-s9 .about-s9-10 {
    font-size: 18px;
  }
  .about-section.about-s3 h3 {
    font-size: 18px;
  }
  .about-section.about-s5 h3 {
    font-size: 30px;
  }
  .about-section.about-s5 h4 {
    font-size: 14px;
  }
  .about-section.about-s6 a,
  .about-section.about-s6 p {
    font-size: 15px;
  }
  .about-section.about-s2 p {
    font-size: 35px;
    line-height: 1;
  }
  .about-section.about-s1 p {
    line-height: 29.88px;
  }
  .about .hero-about .cloud {
    transform: scale(0.8) !important;
  }
  .about h2, .about h2 span.revert-default {
    font-size: 70px;
  }
  .about h2 span {
    font-size: 60px;
    line-height: 1;
  }
  .about-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .about-section.about-s1 {
    margin-top: 0;
  }
  .about-section.about-s2 h2 {
    margin-top: -210px;
    margin-bottom: 160px;
  }
  .about-section.about-s4 h2 span.word2 {
    font-size: 30px;
  }
  .about-section.about-s4 h2 span.word4 {
    margin-left: 85px;
  }
  .about-section.about-frames img {
    width: 130px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 360px;
    margin-right: 40px;
    margin-left: 40px;
  }
  .about-section.about-frames a:nth-child(3) img,
  .about-section.about-frames a:nth-child(6) img {
    margin-left: -80px;
  }
  .about-section.about-s6 h3, .about-section.about-s8 h3, .about-section.about-s10 h3 {
    font-size: 18px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 25px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 50px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 210px;
  }
  .about-section.about-s9 .about-s9-4 {
    margin-top: 140px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 160px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 16px;
    line-height: 21.8176px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 50.9090909091px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 261.818181818px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 203.636363637px;
  }
  .about-section.about-s9 .about-s9-9.push-down {
    margin-top: 20px;
  }
  .about-section.about-s9 .about-s9-6.push-down2 {
    margin-bottom: 14px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 43.6363636363px;
  }
  .about-section.about-s9 .about-s9-11 img {
    width: 170px;
  }
  .about-section.about-s10 svg {
    font-size: 32px;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 70px;
  }
  .mainContent.contact .content-center .content-right p,
  .mainContent.contact .content-center .content-right form input, .mainContent.contact .content-center .content-right form textarea {
    font-size: 16px;
  }
  .mainContent.contact .content-center .content-right form button,
  .mainContent.contact .content-center .content-left .contact-info > a {
    font-size: 14px;
  }
  .mainContent.contact .content-center .content-right form {
    padding-left: 60px;
  }
  .sentinels--screen1 h1 {
    font-size: 120px;
  }
  .sentinels--screen2 h2,
  .sentinels--menu .right h2 {
    font-size: 100px;
  }
  .sentinels--screen1 p {
    font-size: 13px;
  }
  .sentinels--screen3 p,
  .sentinels--menu .right p {
    font-size: 16px;
  }
  .sentinels--screen4 p {
    font-size: 28px;
  }
  .sentinels--menu .right h3 {
    font-size: 18px;
  }
  .sentinels--menu .left .helper-msg {
    left: -48px;
  }
  .sentinels--menu ul {
    margin-left: 50px;
    width: 90%;
  }
  .sentinels--screen3 > div {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 975px) {
  .hero.hero-about .hero-content {
    width: 70%;
  }
  .about .hero-about .cloud {
    transform: scale(0.75) !important;
  }
  .about-section.about-frames img {
    width: 110px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 340px;
  }
  .about-section.about-frames a:nth-child(3) img,
  .about-section.about-frames a:nth-child(6) img {
    margin-top: 100px;
  }
  .about-section.about-frames a:nth-child(2) img,
  .about-section.about-frames a:nth-child(7) img {
    margin-left: 50px;
  }
  .about-section.about-s8 h4 {
    margin-top: 20px;
  }
  .about-section.about-s9 .about-s9-9.fix-line-height {
    font-size: 45px;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 60px;
  }
}
@media only screen and (max-width: 890px) {
  .content-center h1 {
    font-size: 7rem;
    line-height: 1;
  }
  footer .copy-right h4 {
    font-size: 22px;
  }
  footer .copy-right p {
    font-size: 10px;
    line-height: 14px;
    margin-right: 10px;
  }
  .logo img {
    width: 26px;
    height: 43px;
  }
  .logo a {
    font-size: 28px;
  }
  .logo p {
    font-size: 15px;
  }
  .menu-icon:checked + label, .menu-icon:not(:checked) + label {
    top: 42px;
    right: 65px;
  }
  .nav {
    top: 23px;
    right: 50px;
    width: 60px;
    height: 60px;
  }
  .about-section.about-frames img {
    width: 100px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 330px;
  }
  .about-section.about-frames p {
    font-size: 25px;
    line-height: 35px;
  }
  .portfolio.web2 .next-image-box {
    width: 190px;
    height: 95px;
  }
  .next-item-container p {
    font-size: 13px;
  }
  .next-item-container p span {
    font-size: 18px;
  }
  .portfolio.web2 .slider__btn-left {
    font-size: 15px;
  }
  .web2 .enter-sentinels-link span {
    font-size: 14px;
  }
  .portfolio.web2 .enter-sentinels-link p {
    font-size: 11px;
    width: 150px;
  }
  .portfolio-menu li, .portfolio-menu a, .portfolio-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 14px;
  }
  .portfolio.nft .items .nft-count, .portfolio.nft .items .nft-name {
    font-size: 28px;
  }
  .portfolio.nft .items li {
    min-width: 20rem;
  }
  .portfolio.nft .items li img,
  .portfolio.nft .items li .image-container {
    height: 310px;
  }
  .portfolio.nft .items .nft-price {
    font-size: 15px;
  }
  .portfolio.nft .items .view-description {
    font-size: 12px;
  }
}
@media only screen and (max-width: 856px) {
  h1.about-header {
    font-size: 30px;
  }
  .about-section.about-s7 p {
    font-size: 25px;
    margin-bottom: 30px;
  }
  .about-section.about-s6 h2 {
    font-size: 100px;
  }
  .about-section.about-s6 h2 span {
    font-size: 90px;
  }
  .about .hero-about h2, .about-section.about-s8 h4,
  .about-section.about-s1 p,
  .about-section.about-s9 .about-s9-10 {
    font-size: 16px;
  }
  .about-section.about-s3 h3 {
    font-size: 16px;
  }
  .about-section.about-s2 p {
    font-size: 30px;
    line-height: 1;
  }
  .about-section.about-s5 h3 {
    font-size: 20px;
  }
  .about-section.about-s5 h4 {
    font-size: 12px;
  }
  .about-section.about-s6 a,
  .about-section.about-s6 p {
    font-size: 14px;
  }
  .about-section.about-s1 p {
    line-height: 26.56px;
  }
  .about h2, .about h2 span.revert-default {
    font-size: 60px;
  }
  .about h2 span {
    font-size: 50px;
  }
  .about-section.about-s2 h2 {
    margin-top: -180px;
    margin-bottom: 120px;
  }
  .about-section.about-s6 img {
    margin-top: -470px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 310px;
  }
  .about-section.about-frames p {
    font-size: 22px;
    line-height: 32px;
  }
  .about-section.about-s6 h3, .about-section.about-s8 h3, .about-section.about-s10 h3 {
    font-size: 16px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 20px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 40px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 180px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 130px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 15px;
    line-height: 20.454px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 47.7272727273px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 245.454545455px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 190.90909091px;
  }
  .about-section.about-s9 .about-s9-9.fix-line-height {
    font-size: 40px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 40.9090909092px;
  }
  .about-section.about-s10 svg {
    font-size: 28px;
  }
  .portfolio.nft .enter-sentinels-link {
    display: none;
  }
  .mainContent.contact .content-center {
    display: flex;
  }
  .mainContent.contact .content-center .content-left,
  .mainContent.contact .content-center .content-right {
    width: auto;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 50px;
  }
  .mainContent.contact .content-center .content-right p,
  .mainContent.contact .content-center .content-right form input, .mainContent.contact .content-center .content-right form textarea {
    font-size: 15px;
  }
  .mainContent.contact .content-center .content-right form button,
  .mainContent.contact .content-center .content-left .contact-info > a {
    font-size: 13px;
  }
  .mainContent.contact .content-center .content-right form {
    padding-left: 50px;
  }
  .sentinels--screen1 h1 {
    font-size: 100px;
  }
  .sentinels--screen2 h2,
  .sentinels--menu .right h2 {
    font-size: 80px;
  }
  .sentinels--screen1 p {
    font-size: 12px;
  }
  .sentinels--screen3 p,
  .sentinels--menu .right p {
    font-size: 15px;
  }
  .sentinels--screen4 p {
    font-size: 26px;
  }
  .sentinels--menu .right h3 {
    font-size: 16px;
  }
  .view-collection-link .link-text {
    font-size: 12px;
  }
  .sentinels--menu ul li {
    width: 90px;
  }
  .sentinels--menu li div {
    height: 90px;
    width: 90px;
  }
  .sentinels--menu li div span {
    font-size: 11px;
  }
  .screen--container > div {
    padding: 0 30px;
  }
  .screen--container.sentinels--menu > div {
    padding: 0;
  }
  .screen--container.sentinels--menu .right {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .content-center h1 {
    font-size: 6rem;
  }
  footer .copyright p {
    font-size: 9px;
    line-height: 12px;
    margin-right: 7px;
  }
  footer .copyright h4 {
    font-size: 20px;
  }
  .clouds {
    left: 3%;
  }
  h1.about-header {
    font-size: 20px;
  }
  .about-section.about-s7 p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .about-section.about-s6 h2 {
    font-size: 90px;
  }
  .about-section.about-s6 h2 span {
    font-size: 80px;
  }
  .about .hero-about h2, .about-section.about-s8 h4,
  .about-section.about-s1 p,
  .about-section.about-s9 .about-s9-10 {
    font-size: 12px;
  }
  .about-section.about-s5 h3 {
    font-size: 14px;
  }
  .about-section.about-s3 h3 {
    font-size: 14px;
  }
  .about-section.about-s3 p {
    font-size: 10px;
  }
  .about-section.about-s6 a,
  .about-section.about-s6 p {
    font-size: 13px;
  }
  .about-section.about-s2 p {
    font-size: 24px;
    line-height: 1;
  }
  .about-section.about-s1 p {
    line-height: 19.92px;
  }
  .about h2, .about h2 span.revert-default {
    font-size: 50px;
  }
  .about h2 span {
    font-size: 40px;
  }
  .about-section.about-s2 h2 {
    margin-top: -150px;
  }
  .about-section.about-s4 h2 span.word2 {
    font-size: 20px;
  }
  .about-section.about-s4 h2 span.word4 {
    margin-left: 0;
  }
  .about-section.about-s6 img {
    margin-top: -430px;
  }
  .about-section.about-frames img {
    width: 90px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 250px;
  }
  .about-section.about-frames a:nth-child(3) img, .about-section.about-frames a:nth-child(6) img {
    margin-left: -55px;
    margin-top: 80px;
  }
  .about-section.about-frames p {
    font-size: 18px;
    line-height: 28px;
  }
  .about-section.about-s8 h4 {
    margin-top: 10px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 100px;
  }
  .about-section.about-s9 .about-s9-10 {
    width: 280px;
    margin-left: 30px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 16px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 30px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 150px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 14px;
    line-height: 19.0904px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 44.5454545455px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 229.090909091px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 178.181818182px;
  }
  .about-section.about-s9 .about-s9-6.push-down2 {
    margin-bottom: 12px;
  }
  .about-section.about-s9 .about-s9-9.fix-line-height {
    font-size: 36px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 35.1818181818px;
  }
  .about-section.about-s9 .about-s9-11 img {
    width: 150px;
  }
  .about-section.about-s10 svg {
    font-size: 25px;
  }
  .about-section.about-s10 .about-s10-left {
    width: 90%;
  }
  .sentinels--menu ul li {
    width: 80px;
  }
  .sentinels--menu li div {
    height: 80px;
    width: 80px;
  }
  .sentinels--menu li div span {
    font-size: 10px;
  }
  .sentinels--loading img {
    margin: 0;
  }
}
@media only screen and (max-width: 661px) {
  .content-center h1 {
    font-size: 5rem;
  }
  .clouds {
    left: 0%;
  }
  .hero.hero-about .hero-content {
    width: 120%;
  }
  .about .hero-about .cloud {
    transform: scale(0.7) !important;
  }
  .hero.hero-about .hero-content-right img {
    margin-left: 60px;
  }
  .about-section.about-s2 > div img {
    width: 65%;
  }
  .about-section.about-s6 img {
    margin-top: -380px;
  }
  .about-section.about-s7 .s7-buttons div {
    margin-top: 10px;
  }
  .about-section.about-s7 .s7-buttons svg {
    margin-top: 26px;
  }
  .about-section.about-s7 .s7-buttons .link-right svg {
    margin-top: 14px;
  }
  .about-section.about-s9 .about-s9-4 {
    margin-top: 130px;
  }
  .about-section.about-s9 .about-s9-9.fix-line-height {
    font-size: 30px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 28.727272px;
  }
  .about-section.about-s9 .about-s9-11 img {
    width: 120px;
  }
  .about-section.about-s10 svg {
    font-size: 22px;
  }
  .portfolio.web2 .next-image-box {
    width: 140px;
    height: 70px;
  }
  .next-item-container p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .next-item-container p span {
    font-size: 15px;
  }
  .portfolio.web2 .slider__btn-left {
    font-size: 13px;
  }
  .web2 .enter-sentinels-link span {
    font-size: 12px;
  }
  .portfolio.web2 .enter-sentinels-link p {
    display: none;
  }
  .portfolio-menu li, .portfolio-menu a, .portfolio-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 12px;
  }
  .portfolio.web2 .enter-sentinels-link {
    bottom: 18%;
    left: 15%;
  }
  .portfolio.nft .items .nft-count, .portfolio.nft .items .nft-name {
    font-size: 24px;
  }
  .portfolio.nft .items li {
    min-width: 14rem;
  }
  .portfolio.nft .items li img,
  .portfolio.nft .items li .image-container {
    height: 240px;
  }
  .portfolio.nft .items .nft-price {
    font-size: 13px;
  }
  .portfolio.nft .items .view-description {
    font-size: 12px;
  }
  .mainContent.contact .content-center {
    display: block;
  }
  .mainContent.contact .content-center .content-left, .mainContent.contact .content-center .content-right {
    height: auto;
  }
  body.hidden.contact {
    overflow-y: auto;
  }
  footer,
  body.hidden.contact footer {
    position: static;
  }
  body.hidden footer {
    position: absolute;
  }
  .mainContent.contact .content-center .content-left h1, .mainContent.contact .content-center .content-left h1 span {
    text-align: center;
  }
  .mainContent.contact .content-center .content-left {
    margin-top: 40px;
  }
  .mainContent.contact .content-center .content-left, .mainContent.contact .content-center .content-right {
    margin-bottom: 80px;
  }
  .mainContent.contact .content-center .content-left h1,
  .mainContent.contact .content-center .content-left h1 span {
    font-size: 40px;
  }
  .mainContent.contact .content-center .content-right p,
  .mainContent.contact .content-center .content-right form input, .mainContent.contact .content-center .content-right form textarea {
    font-size: 14px;
  }
  .mainContent.contact .content-center .content-right form button,
  .mainContent.contact .content-center .content-left .contact-info > a {
    font-size: 12px;
  }
  .mainContent.contact .content-center .content-left h1 span {
    display: inline-block;
  }
  .mainContent.contact .content-center .content-right form {
    padding-left: 0;
  }
  .sentinels--screen1 h1 {
    font-size: 80px;
  }
  .sentinels--screen2 h2,
  .sentinels--menu .right h2 {
    font-size: 60px;
  }
  .sentinels--screen1 p {
    font-size: 12px;
  }
  .sentinels--screen3 p,
  .sentinels--menu .right p {
    font-size: 12px;
  }
  .sentinels--screen4 p {
    font-size: 22px;
  }
  .sentinels--menu .right h3 {
    font-size: 14px;
  }
  .view-collection-link .link-text {
    font-size: 12px;
  }
  .screen--container .view-collection-link {
    bottom: 80px;
  }
  .view-collection-link .link-text {
    font-size: 11px;
    line-height: 14px;
  }
  .sentinels--menu .left .helper-msg {
    font-size: 11px;
  }
  .sentinels--menu ul {
    margin-left: 30px;
  }
  .sentinels--menu .left .helper-msg {
    left: -58px;
  }
}
@media only screen and (max-width: 548px) {
  .content-center h1 {
    font-size: 4.5rem;
  }
  .logo img {
    width: 16px;
    height: 25px;
  }
  .logo a {
    font-size: 18px;
  }
  .logo p {
    font-size: 11px;
  }
  .menu-icon:checked + label, .menu-icon:not(:checked) + label {
    top: 36px;
    right: 60px;
  }/*
  .menu-icon:checked + label:before, .menu-icon:not(:checked) + label:before {
    width: 10px;
    height: 16px;
  }
  .menu-icon:checked + label:after, .menu-icon:not(:checked) + label:after {
    width: 15px;
    right: -2px;
  }*/
  .nav {
    top: 23px;
    right: 50px;
    width: 50px;
    height: 50px;
  }
  .hero.hero-about .hero-content-right img {
    margin-left: 40px;
  }
  .about .hero-about .cloud {
    transform: scale(0.6) !important;
  }
  .about-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-section.about-s2 > div img {
    width: 80%;
  }
  .about-section.about-s4 h2 span.word2 {
    font-size: 16px;
  }
  .about-section.about-s4 .poster-carousel li img {
    height: 360px;
  }
  .about-section.about-s6 h2 {
    font-size: 60px;
  }
  .about-section.about-s6 h2 span {
    font-size: 50px;
  }
  .about-section.about-s6 img {
    margin-top: -300px;
  }
  .about-section.about-s6 a,
  .about-section.about-s6 p {
    font-size: 12px;
  }
  .about-section.about-s7 .s7-buttons span {
    font-size: 12px;
  }
  .about-section.about-s7 .s7-buttons div {
    margin-top: 10px;
    font-size: 12px;
  }
  .about-section.about-s7 .s7-buttons svg {
    margin-top: 20px;
  }
  .about-section.about-frames img {
    width: 70px;
  }
  .about-section.about-frames a:nth-child(4) img {
    width: 200px;
  }
  .about-section.about-frames p {
    font-size: 15px;
    line-height: 25px;
  }
  .about-section.about-s9 .about-s9-3 {
    margin-top: 30px;
    margin-left: 5px;
  }

  .about-section.about-s9 .about-s9-4 {
    margin-top: 60px;
    margin-left: -10px;
  }
  .about-section.about-s9 > div {
    margin-bottom: 80px;
  }
  .about-section.about-s9 .about-s9-1,
  .about-section.about-s9 .about-s9-4 {
    font-size: 12px;
  }
  .about-section.about-s9 .about-s9-3 {
    font-size: 20px;
  }
  .about-section.about-s9 .about-s9-2 {
    font-size: 120px;
  }
  .about-section.about-s9 .about-s9-7, .about-section.about-s10 .about-s9-7 {
    font-size: 12px;
    line-height: 16.3632px;
  }
  .about-section.about-s9 .about-s9-6, .about-section.about-s9 .about-s9-8,
  .about-section.about-s9 .about-s9-9 {
    font-size: 38.1818181818px;
  }
  .about-section.about-s9 .about-s9-5 {
    font-size: 196.363636364px;
  }
  .about-section.about-s9 .about-s9-5.smaller {
    font-size: 152.727272728px;
  }
  .about-section.about-s9 .about-s9-10 {
    width: 200px;
  }
  .about-section.about-s9 .about-s9-9.fix-line-height {
    font-size: 24px;
  }
  .about-section .about-s9-9.fix-line-height img {
    top: 6px;
  }
  .about-section.about-s9 .about-s9-11 {
    font-size: 22.7272727273px;
  }
  .about-section.about-s9 .about-s9-11 img {
    width: 100px;
  }
  .about-section.about-s10 svg {
    font-size: 16px;
  }
  .about-section.about-s10 .about-s10-left {
    width: 150%;
  }
  .portfolio.web2 .next-image-box {
    width: 140px;
    height: 70px;
  }
  .next-item-container p {
    font-size: 11px;
    margin-bottom: 0;
  }
  .next-item-container p span {
    font-size: 15px;
  }
  .portfolio.web2 .slider__btn-left {
    font-size: 13px;
  }
  .web2 .enter-sentinels-link span {
    font-size: 11px;
  }
  .portfolio-menu li, .portfolio-menu a, .portfolio-menu .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 11px;
  }
  .sentinels--menu ul li {
    width: 60px;
  }
  .sentinels--menu li div {
    height: 60px;
    width: 60px;
  }
}
